import { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setEnabled as setLimitHistoryEnabled } from 'LimitHistory/duck';
import StakeLimitsView from '../stake_limits_view';
import reducer, { initialState, actions } from './reducer';
import TranslationsCtx from '../../translations_context';

const StakeLimitsContainer = (props) => {
  const {
    footer, translationsNamespace, endpoints, scopePeriodsByTypeId,
  } = props;

  const [{
    configs: typeConfigs,
    stakeLimitsTypes,
    stakeLimitsPeriods,
    confirmationModalOpened,
    stakeLimitsTypeID,
    userStakeLimits,
    configsLoaded,
    stakeLimitsTypesLoaded,
    ...rest
  }, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchStakeLimitsPeriodsInit());

    endpoints.fetchLimitPeriods().then((periods) => {
      dispatch(actions.fetchStakeLimitsPeriodsComplete(periods));
    });
  }, []);

  useEffect(() => {
    endpoints.fetchLimitTypes().then((types) => {
      dispatch(actions.fetchStakeLimitsTypesComplete(types));
    });
  }, []);

  useEffect(() => {
    endpoints.fetchGeneralConfig().then((config) => {
      reduxDispatch(setLimitHistoryEnabled(config.limitHistoryEnabled, translationsNamespace));
    });
    endpoints.fetchLimitConfigs().then((configs) => {
      dispatch(actions.fetchStakeLimitsConfigsComplete(configs));
    });
  }, []);

  useEffect(() => {
    dispatch(actions.fetchUserStakeLimitsInit());

    endpoints.fetchLimits().then((stakeLimits) => {
      dispatch(actions.fetchUserStakeLimitsComplete(stakeLimits));
    });
  }, []);

  useEffect(() => {
    if (stakeLimitsTypesLoaded && configsLoaded) {
      dispatch(actions.filterLimitsTypes());
    }
  }, [stakeLimitsTypesLoaded, configsLoaded]);

  const handleSubmit = (values, form) => {
    if (!confirmationModalOpened) {
      return dispatch(actions.showConfirmationModal(
        values.selectedPeriod.id ? 'ON_CREATE' : 'ON_NO_LIMIT_CREATE',
      ));
    }
    dispatch(actions.closeModal('confirmation'));

    const periodId = values.selectedPeriod.id;

    const apiCall = () => {
      if (periodId) {
        return endpoints.createLimit({
          amount: +values.amount,
          period_id: periodId,
          type_id: stakeLimitsTypeID,
        });
      }

      return endpoints.createNoLimitLimit(stakeLimitsTypeID);
    };

    return apiCall().then((stakeLimit) => {
      form.reset();
      if (stakeLimit?.id) {
        dispatch(actions.createStakeLimitSuccess(stakeLimit));
      } else {
        dispatch(actions.createStakeLimitFailure());
      }
    });
  };

  const handleStakeLimitsRemove = ({ ids, noLimitActiveLimitRemoval }) => {
    dispatch(actions.removeUserStakeLimitsInit());

    return endpoints.removeLimits(ids).then((stakeLimits) => {
      if (stakeLimits.length) {
        if (noLimitActiveLimitRemoval) {
          dispatch(actions.removeUserNoLimitActiveLimit({
            stakeLimit: stakeLimits[0],
          }));
        } else {
          dispatch(actions.removeUserStakeLimitsSuccess({ stakeLimits }));
        }
      } else {
        dispatch(actions.removeUserStakeLimitsFailure());
      }
    });
  };

  const typedStakeLimits = userStakeLimits.filter((limit) => limit.typeId === stakeLimitsTypeID);

  return (
    <TranslationsCtx.Provider value={translationsNamespace}>
      <StakeLimitsView
        stakeLimitsTypes={stakeLimitsTypes}
        stakeLimitsPeriods={scopePeriodsByTypeId ? (
          stakeLimitsPeriods.filter((p) => p.typeId === stakeLimitsTypeID)
        ) : stakeLimitsPeriods}
        confirmationModalOpened={confirmationModalOpened}
        typedStakeLimits={typedStakeLimits}
        stakeLimitsTypeID={stakeLimitsTypeID}
        currentConfig={typeConfigs.find((config) => config.typeId === stakeLimitsTypeID)}
        configsLoaded={configsLoaded}
        footer={footer}
        onStakeLimitsTypesTabClick={(id) => dispatch(actions.setStakeLimitsTypeID(id))}
        onFormSubmit={handleSubmit}
        onRemoveBtnClick={() => dispatch(actions.showConfirmationModal('ON_REMOVE'))}
        onStakeLimitsRemoveConfirm={handleStakeLimitsRemove}
        onModalClose={(modalType) => dispatch(actions.closeModal(modalType))}
        {...rest}
      />
    </TranslationsCtx.Provider>
  );
};

StakeLimitsContainer.propTypes = {
  footer: PropTypes.element,
  translationsNamespace: PropTypes.string.isRequired,
  endpoints: PropTypes.shape({
    fetchLimitPeriods: PropTypes.func,
    fetchLimitTypes: PropTypes.func,
    createLimit: PropTypes.func,
    createNoLimitLimit: PropTypes.func,
    fetchLimits: PropTypes.func,
    removeLimits: PropTypes.func,
    fetchLimitConfigs: PropTypes.func,
    fetchGeneralConfig: PropTypes.func,
  }).isRequired,
  scopePeriodsByTypeId: PropTypes.bool,
};

StakeLimitsContainer.defaultProps = {
  footer: null,
  scopePeriodsByTypeId: false,
};

export default StakeLimitsContainer;
