import { useContext } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import classnames from 'classnames';
import TranslationsCtx from '../translations_context';

const NoLimitActiveLimitRow = ({ onClick, checked }) => {
  const tNamespace = useContext(TranslationsCtx);
  const classes = classnames('stake-limits__remove-limits__row', { active: checked });

  const checkboxLabelClasses = classnames('account-form__checkbox', { active: checked });

  return (
    <div onClick={onClick} className={classes}>
      <div className="bvs-checkbox-card__checkbox">
        <label htmlFor="sl_checkbox_no_limit" className={checkboxLabelClasses}>
          <input type="checkbox" className="account-form__hidden" id="sl_checkbox_no_limit" checked={checked} />
          <div>
            {t(`${tNamespace}.current_limits_table.no_limit_active`)}
          </div>
        </label>
      </div>
    </div>
  );
};

NoLimitActiveLimitRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default NoLimitActiveLimitRow;
