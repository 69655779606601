import { useContext } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { formatAmount } from '../helpers';
import TranslationsCtx from '../translations_context';

const AggregatedStakeLimitRow = ({
  label,
  currentAmount,
  remaining,
  pendingAmount,
  isNoLimitPendingLimit,
}) => {
  const tNamespace = useContext(TranslationsCtx);
  let pendingValue;

  if (isNoLimitPendingLimit) {
    pendingValue = t(`${tNamespace}.current_limits_table.td.pending.no_limit`);
  } else if (pendingAmount === 0) {
    pendingValue = t(`${tNamespace}.current_limits_table.td.pending.removal`);
  } else if (!pendingAmount) {
    pendingValue = '-';
  } else {
    pendingValue = formatAmount({ amount: pendingAmount });
  }

  return (
    <div className="stake-limits__remove-limits__row">
      <div>{label}</div>
      <div>
        {currentAmount ? formatAmount({ amount: currentAmount }) : '-'}
      </div>
      <div>
        {remaining ? formatAmount({ amount: remaining }) : '-'}
      </div>
      <div>{pendingValue}</div>
    </div>
  );
};

AggregatedStakeLimitRow.propTypes = {
  label: PropTypes.string.isRequired,
  currentAmount: PropTypes.number,
  remaining: PropTypes.number,
  pendingAmount: PropTypes.number,
  isNoLimitPendingLimit: PropTypes.bool.isRequired,
};

AggregatedStakeLimitRow.defaultProps = {
  currentAmount: null,
  remaining: null,
  pendingAmount: null,
};

export default AggregatedStakeLimitRow;
