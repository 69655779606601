import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import TranslationsCtx from '../translations_context';

const SuccessSubmitModal = ({ children, onClose }) => {
  const tNamespace = useContext(TranslationsCtx);

  return (
    <Modal
      title={t(`${tNamespace}.success_submit_modal.title`)}
      success
      icon
      actions={[{
        id: 'ok-btn',
        label: t('close'),
        primary: true,
        inverse: true,
        onClick: onClose,
      }]}
    >
      {children}
    </Modal>
  );
};

SuccessSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element,
};

SuccessSubmitModal.defaultProps = {
  children: null,
};

export default SuccessSubmitModal;
