const SET_STAKE_LIMITS_TYPE_ID = 'SET_STAKE_LIMITS_TYPE_ID';
const FETCH_STAKE_LIMITS_PERIODS_INIT = 'FETCH_STAKE_LIMITS_PERIODS_INIT';
const FETCH_STAKE_LIMITS_PERIODS_COMPLETE = 'FETCH_STAKE_LIMITS_PERIODS_COMPLETE';
const FETCH_STAKE_LIMITS_TYPES_COMPLETE = 'FETCH_STAKE_LIMITS_TYPES_COMPLETE';
const FETCH_STAKE_LIMITS_CONFIGS_COMPLETE = 'FETCH_STAKE_LIMITS_CONFIGS_COMPLETE';
const FETCH_USER_STAKE_LIMITS_INIT = 'FETCH_USER_STAKE_LIMITS_INIT';
const FETCH_USER_STAKE_LIMITS_COMPLETE = 'FETCH_USER_STAKE_LIMITS_COMPLETE';
const REMOVE_USER_STAKE_LIMITS_INIT = 'REMOVE_USER_STAKE_LIMITS_INIT';
const REMOVE_USER_STAKE_LIMITS_SUCCESS = 'REMOVE_USER_STAKE_LIMITS_SUCCESS';
const REMOVE_USER_NO_LIMIT_ACTIVE_LIMIT = 'REMOVE_USER_NO_LIMIT_ACTIVE_LIMIT';
const REMOVE_USER_STAKE_LIMITS_FAILURE = 'REMOVE_USER_STAKE_LIMITS_FAILURE';
const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
const CREATE_STAKE_LIMIT_SUCCESS = 'CREATE_STAKE_LIMIT_SUCCESS';
const CREATE_STAKE_LIMIT_FAILURE = 'CREATE_STAKE_LIMIT_FAILURE';
const CLOSE_MODAL = 'CLOSE_MODAL';
const FILTER_LIMITS_TYPES = 'FILTER_LIMITS_TYPES';

const setStakeLimitsTypeID = (id) => ({
  type: SET_STAKE_LIMITS_TYPE_ID,
  id,
});

const fetchStakeLimitsPeriodsInit = () => ({
  type: FETCH_STAKE_LIMITS_PERIODS_INIT,
});

const fetchStakeLimitsPeriodsComplete = (periods) => ({
  type: FETCH_STAKE_LIMITS_PERIODS_COMPLETE,
  periods,
});

const fetchStakeLimitsTypesComplete = (types) => ({
  type: FETCH_STAKE_LIMITS_TYPES_COMPLETE,
  types,
});

const fetchStakeLimitsConfigsComplete = (configs) => ({
  type: FETCH_STAKE_LIMITS_CONFIGS_COMPLETE,
  configs,
});

const fetchUserStakeLimitsInit = () => ({
  type: FETCH_USER_STAKE_LIMITS_INIT,
});

const fetchUserStakeLimitsComplete = (userStakeLimits) => ({
  type: FETCH_USER_STAKE_LIMITS_COMPLETE,
  userStakeLimits,
});

const removeUserStakeLimitsInit = () => ({
  type: REMOVE_USER_STAKE_LIMITS_INIT,
});

const removeUserStakeLimitsSuccess = ({ stakeLimits }) => ({
  type: REMOVE_USER_STAKE_LIMITS_SUCCESS,
  stakeLimits,
});

const removeUserNoLimitActiveLimit = ({ stakeLimit }) => ({
  type: REMOVE_USER_NO_LIMIT_ACTIVE_LIMIT,
  stakeLimit,
});

const removeUserStakeLimitsFailure = () => ({
  type: REMOVE_USER_STAKE_LIMITS_FAILURE,
});

const showConfirmationModal = (ctx) => ({
  type: SHOW_CONFIRMATION_MODAL,
  ctx,
});

const createStakeLimitSuccess = (stakeLimit) => ({
  type: CREATE_STAKE_LIMIT_SUCCESS,
  stakeLimit,
});

const createStakeLimitFailure = () => ({
  type: CREATE_STAKE_LIMIT_FAILURE,
});

const closeModal = (modalType) => ({
  type: CLOSE_MODAL,
  modalType,
});

const filterLimitsTypes = () => ({
  type: FILTER_LIMITS_TYPES,
});

export const actions = {
  setStakeLimitsTypeID,
  fetchStakeLimitsPeriodsInit,
  fetchStakeLimitsPeriodsComplete,
  fetchStakeLimitsTypesComplete,
  fetchStakeLimitsConfigsComplete,
  fetchUserStakeLimitsInit,
  fetchUserStakeLimitsComplete,
  removeUserStakeLimitsInit,
  removeUserStakeLimitsSuccess,
  removeUserNoLimitActiveLimit,
  removeUserStakeLimitsFailure,
  showConfirmationModal,
  createStakeLimitSuccess,
  createStakeLimitFailure,
  closeModal,
  filterLimitsTypes,
};

export const initialState = {
  stakeLimitsTypeID: null,
  stakeLimitsPeriods: [],
  stakeLimitsTypes: [],
  stakeLimitsTypesLoaded: false,
  configs: [],
  configsLoaded: false,
  stakeLimitsPeriodsAreLoading: false,
  userStakeLimits: [],
  userStakeLimitsAreLoading: false,
  userStakeLimitsAreRemoving: false,
  confirmationModalOpened: false,
  confirmationModalCtx: null,
  successModalOpened: false,
  failureModalOpened: false,
  showCoolOfPeriodMessage: false,
};

export default (state, action) => {
  switch (action.type) {
    case SET_STAKE_LIMITS_TYPE_ID:
      return { ...state, stakeLimitsTypeID: action.id };
    case FETCH_STAKE_LIMITS_PERIODS_INIT:
      return { ...state, stakeLimitsPeriodsAreLoading: true };
    case FETCH_STAKE_LIMITS_PERIODS_COMPLETE:
      return {
        ...state,
        stakeLimitsPeriodsAreLoading: false,
        stakeLimitsPeriods: action.periods,
      };
    case FETCH_STAKE_LIMITS_TYPES_COMPLETE:
      return {
        ...state,
        stakeLimitsTypes: action.types,
        stakeLimitsTypesLoaded: true,
      };
    case FETCH_STAKE_LIMITS_CONFIGS_COMPLETE:
      return {
        ...state,
        configs: action.configs,
        configsLoaded: true,
      };
    case FETCH_USER_STAKE_LIMITS_INIT:
      return { ...state, userStakeLimitsAreLoading: true };
    case FETCH_USER_STAKE_LIMITS_COMPLETE:
      return {
        ...state,
        userStakeLimitsAreLoading: false,
        userStakeLimits: action.userStakeLimits,
      };
    case CREATE_STAKE_LIMIT_SUCCESS: {
      let filteredStakeLimits;
      let showCoolOfPeriodMessage = false;

      const { stakeLimit } = action;
      const { type, period } = stakeLimit;

      if (stakeLimit.active) {
        filteredStakeLimits = state.userStakeLimits.filter((limit) => (
          !(limit.type === type && (limit.period === period || !limit.period))
        ));
      } else if (stakeLimit.pending) {
        filteredStakeLimits = state.userStakeLimits.filter((limit) => (
          !(limit.type === type && limit.pending && (limit.period === period || !limit.period))
        ));
        showCoolOfPeriodMessage = true;
      }

      return {
        ...state,
        successModalOpened: true,
        userStakeLimits: [...filteredStakeLimits, stakeLimit],
        showCoolOfPeriodMessage,
      };
    }
    case CREATE_STAKE_LIMIT_FAILURE:
      return { ...state, failureModalOpened: true };
    case REMOVE_USER_STAKE_LIMITS_INIT:
      return {
        ...state,
        userStakeLimitsAreRemoving: true,
        confirmationModalOpened: false,
      };
    case REMOVE_USER_STAKE_LIMITS_SUCCESS: {
      const idsForRemoval = action.stakeLimits.reduce((acc, pendingLimit) => {
        state.userStakeLimits.forEach((limit) => {
          const { type, period } = pendingLimit;

          if (limit.type === type && limit.period === period && limit.pending) {
            acc.push(limit.id);
          }
        });

        return acc;
      }, []);

      return {
        ...state,
        userStakeLimitsAreRemoving: false,
        userStakeLimits: [
          ...state.userStakeLimits.filter((limit) => !idsForRemoval.includes(limit.id)),
          ...action.stakeLimits,
        ],
        successModalOpened: true,
        showCoolOfPeriodMessage: true,
      };
    }
    case REMOVE_USER_NO_LIMIT_ACTIVE_LIMIT: {
      const { period, type } = action.stakeLimit;

      return {
        ...state,
        userStakeLimitsAreRemoving: false,
        userStakeLimits: state.userStakeLimits.filter((limit) => (
          !(limit.period === period && limit.type === type)
        )),
        successModalOpened: true,
      };
    }
    case REMOVE_USER_STAKE_LIMITS_FAILURE:
      return { ...state, failureModalOpened: true };
    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModalOpened: true,
        confirmationModalCtx: action.ctx,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        [`${action.modalType}ModalOpened`]: false,
        confirmationModalCtx: null,
        showCoolOfPeriodMessage: false,
      };
    case FILTER_LIMITS_TYPES: {
      const filteredTypes = state.configs.reduce((acc, config) => {
        const limitType = state.stakeLimitsTypes.find((type) => type.id === config.typeId);

        if (limitType) acc.push(limitType);

        return acc;
      }, []);

      const currentStakeLimitsType = filteredTypes[0];

      return {
        ...state,
        stakeLimitsTypes: filteredTypes,
        stakeLimitsTypeID: currentStakeLimitsType.id,
      };
    }
    default:
      throw new Error();
  }
};
