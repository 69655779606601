import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';
import ConfirmationModal from './confirmation_modal';
import ActiveStakeLimitRow from './active_stake_limit_row';
import NoLimitActiveLimitRow from './no_limit_active_limit_row';
import { userStakeLimitsType, stakeLimitsPeriodsType } from '../types';
import TranslationsCtx from '../translations_context';

const RemoveConfirmationModal = ({
  typedStakeLimits,
  stakeLimitsPeriods,
  onCancel,
  onContinue,
}) => {
  const tNamespace = useContext(TranslationsCtx);
  const [checkedIds, setCheckedIds] = useState([]);

  const getActiveStakeLimitsByPeriod = (periodName) => typedStakeLimits.find((limit) => (
    limit.period === periodName && limit.active
  ));

  const activeStakeLimitsByPeriod = stakeLimitsPeriods.map((period) => (
    getActiveStakeLimitsByPeriod(period.name)
  )).filter(Boolean);

  const toggleCheckboxes = () => {
    if (activeStakeLimitsByPeriod.length !== checkedIds.length) {
      setCheckedIds(activeStakeLimitsByPeriod.map((limit) => limit.id));
    } else {
      setCheckedIds([]);
    }
  };

  const handleCheck = (e, id) => {
    e.preventDefault();
    if (checkedIds.indexOf(id) === -1) {
      setCheckedIds([id, ...checkedIds]);
    } else {
      setCheckedIds(checkedIds.filter((i) => i !== id));
    }
  };

  const noLimitActiveLimit = typedStakeLimits.find((limit) => limit.active && !limit.period);

  return (
    <ConfirmationModal
      onNoBtnClick={onCancel}
      onYesBtnClick={() => onContinue({
        ids: checkedIds,
        noLimitActiveLimitRemoval: checkedIds.includes(noLimitActiveLimit?.id),
      })}
      yesBtnDisabled={!checkedIds.length}
    >
      <>
        <div className="stake-limits__remove-limits">
          <p>{t(`${tNamespace}.remove_confirmation_modal.select_limits`)}</p>
          {noLimitActiveLimit ? (
            <NoLimitActiveLimitRow
              onClick={(e) => handleCheck(e, noLimitActiveLimit.id)}
              checked={checkedIds.indexOf(noLimitActiveLimit.id) !== -1}
            />
          ) : (
            <>
              <div className="stake-limits__remove-limits__row--header">
                <div>{t(`${tNamespace}.current_limits_table.th.current`)}</div>
                <div>{t(`${tNamespace}.current_limits_table.th.amount`)}</div>
                <div>{t(`${tNamespace}.current_limits_table.th.remaining`)}</div>
              </div>
              {activeStakeLimitsByPeriod.map((limit) => (
                <ActiveStakeLimitRow
                  key={limit.id}
                  onClick={(e) => handleCheck(e, limit.id)}
                  checked={checkedIds.indexOf(limit.id) !== -1}
                  label={limit.periodLabel}
                  amount={limit.amount}
                  remaining={limit.remaining}
                />
              ))}
            </>
          )}
        </div>
        <div>
          {activeStakeLimitsByPeriod.length > 1 ? (
            <Button
              onClick={toggleCheckboxes}
              secondary
              inverse
              label={activeStakeLimitsByPeriod.length === checkedIds.length ? (
                t(`${tNamespace}.remove_confirmation_modal.clear_all`)
              ) : (
                t(`${tNamespace}.remove_confirmation_modal.select_all`)
              )}
            />
          ) : null}
        </div>
      </>
    </ConfirmationModal>
  );
};

RemoveConfirmationModal.propTypes = {
  typedStakeLimits: userStakeLimitsType.isRequired,
  stakeLimitsPeriods: stakeLimitsPeriodsType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default RemoveConfirmationModal;
