import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import TranslationsCtx from '../translations_context';

const ConfirmationModal = ({
  onNoBtnClick,
  onYesBtnClick,
  children,
  yesBtnDisabled,
}) => {
  const tNamespace = useContext(TranslationsCtx);

  return (
    <Modal
      info
      icon
      actions={[
        {
          id: 'no-btn',
          secondary: true,
          inverse: true,
          label: t(`${tNamespace}.confirmation_modal.false`),
          onClick: onNoBtnClick,
        },
        {
          id: 'yes-btn',
          secondary: true,
          label: t(`${tNamespace}.confirmation_modal.true`),
          onClick: onYesBtnClick,
          disabled: yesBtnDisabled,
        },
      ]}
    >
      {children}
      <p>{t(`${tNamespace}.confirmation_modal.should_proceed`)}</p>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  onNoBtnClick: PropTypes.func.isRequired,
  onYesBtnClick: PropTypes.func.isRequired,
  children: PropTypes.element,
  yesBtnDisabled: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  children: null,
  yesBtnDisabled: false,
};

export default ConfirmationModal;
