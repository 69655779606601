import { useContext } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Spinner } from 'bv-components';
import FormContainer from './form_container';
import StakeLimitsTypesTabs from './stake_limits_types_tabs';
import CurrentStakeLimitsTable from './current_stake_limits_table';
import SuccessSubmitModal from './success_submit_modal';
import FailureSubmitModal from './failure_submit_modal';
import RemoveConfirmationModal from './remove_confirmation_modal';
import TranslationsCtx from '../translations_context';
import {
  configDataType,
  stakeLimitsPeriodsType,
  stakeLimitsTypesType,
  userStakeLimitsType,
} from '../types';

const StakeLimitsView = (props) => {
  const {
    stakeLimitsTypeID,
    stakeLimitsPeriodsAreLoading,
    userStakeLimitsAreLoading,
    userStakeLimitsAreRemoving,
    confirmationModalCtx,
    typedStakeLimits,
    stakeLimitsPeriods,
    currentConfig,
    configsLoaded,
    stakeLimitsTypes,
    showCoolOfPeriodMessage,
    footer,
    confirmationModalOpened,
    successModalOpened,
    failureModalOpened,
    onStakeLimitsTypesTabClick,
    onFormSubmit,
    onRemoveBtnClick,
    onStakeLimitsRemoveConfirm,
    onModalClose,
  } = props;

  const tNamespace = useContext(TranslationsCtx);

  if (!stakeLimitsTypeID
    || stakeLimitsPeriodsAreLoading
    || userStakeLimitsAreLoading
    || !configsLoaded
  ) return <Spinner />;

  let confirmationModal = null;

  if (confirmationModalCtx === 'ON_REMOVE') {
    confirmationModal = (
      <RemoveConfirmationModal
        typedStakeLimits={typedStakeLimits}
        stakeLimitsPeriods={stakeLimitsPeriods}
        onCancel={() => onModalClose('confirmation')}
        onContinue={onStakeLimitsRemoveConfirm}
        coolOffPeriod={currentConfig.coolOffPeriod}
      />
    );
  }

  return (
    <>
      <StakeLimitsTypesTabs
        stakeLimitsTypes={stakeLimitsTypes}
        stakeLimitsTypeID={stakeLimitsTypeID}
        onChange={onStakeLimitsTypesTabClick}
      />
      <FormContainer
        stakeLimitsPeriods={
          currentConfig.noLimitAllowed ? (
            [
              ...stakeLimitsPeriods,
              { id: null, name: 'no_limit', label: t('account_form.period.no_limit') },
            ]
          ) : stakeLimitsPeriods
        }
        noLimitAllowed={currentConfig.noLimitAllowed}
        defaultPeriod={currentConfig.defaultPeriod}
        onSubmit={onFormSubmit}
        confirmationModalCtx={confirmationModalCtx}
        onConfirmModalNoClick={() => onModalClose('confirmation')}
      />
      <CurrentStakeLimitsTable
        typedStakeLimits={typedStakeLimits}
        stakeLimitsPeriods={stakeLimitsPeriods}
        onRemoveBtnClick={onRemoveBtnClick}
        userStakeLimitsAreRemoving={userStakeLimitsAreRemoving}
        hideRemoveBtn={currentConfig.limitsRequired}
      />
      {footer}
      {confirmationModalOpened && confirmationModal}
      {successModalOpened && (
        <SuccessSubmitModal onClose={() => onModalClose('success')}>
          {currentConfig.coolOffPeriod && showCoolOfPeriodMessage ? (
            <p>
              {t(`${tNamespace}.cool_off_period.message`, { period: currentConfig.coolOffPeriod })}
            </p>
          ) : null}
        </SuccessSubmitModal>
      )}
      {failureModalOpened && <FailureSubmitModal onClose={() => onModalClose('failure')} />}
    </>
  );
};

StakeLimitsView.propTypes = {
  stakeLimitsTypeID: PropTypes.number,
  stakeLimitsPeriodsAreLoading: PropTypes.bool.isRequired,
  userStakeLimitsAreLoading: PropTypes.bool.isRequired,
  userStakeLimitsAreRemoving: PropTypes.bool.isRequired,
  confirmationModalCtx: PropTypes.string,
  typedStakeLimits: userStakeLimitsType.isRequired,
  stakeLimitsPeriods: stakeLimitsPeriodsType.isRequired,
  currentConfig: configDataType,
  configsLoaded: PropTypes.bool.isRequired,
  stakeLimitsTypes: stakeLimitsTypesType.isRequired,
  showCoolOfPeriodMessage: PropTypes.bool.isRequired,
  footer: PropTypes.element,
  confirmationModalOpened: PropTypes.bool.isRequired,
  successModalOpened: PropTypes.bool.isRequired,
  failureModalOpened: PropTypes.bool.isRequired,
  onStakeLimitsTypesTabClick: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onRemoveBtnClick: PropTypes.func.isRequired,
  onStakeLimitsRemoveConfirm: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

StakeLimitsView.defaultProps = {
  stakeLimitsTypeID: null,
  confirmationModalCtx: null,
  footer: null,
  currentConfig: {},
};

export default StakeLimitsView;
