import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import FormView from './form_view';
import { stakeLimitsPeriodsType } from '../types';

const FormContainer = ({
  stakeLimitsPeriods,
  defaultPeriod,
  onSubmit,
  confirmationModalCtx,
  onConfirmModalNoClick,
}) => {
  const initialValues = {};
  const showStakeLimitsPeriods = stakeLimitsPeriods.length > 1;
  if (!showStakeLimitsPeriods) {
    // eslint-disable-next-line prefer-destructuring
    initialValues.selectedPeriod = stakeLimitsPeriods[0];
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(props) => (
        <FormView
          showStakeLimitsPeriods={showStakeLimitsPeriods}
          stakeLimitsPeriods={stakeLimitsPeriods}
          defaultPeriod={defaultPeriod}
          confirmationModalCtx={confirmationModalCtx}
          onConfirmModalNoClick={onConfirmModalNoClick}
          {...props}
        />
      )}
    </Form>
  );
};

FormContainer.propTypes = {
  stakeLimitsPeriods: stakeLimitsPeriodsType.isRequired,
  defaultPeriod: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  confirmationModalCtx: PropTypes.string,
  onConfirmModalNoClick: PropTypes.func.isRequired,
};

FormContainer.defaultProps = {
  confirmationModalCtx: null,
};

export default FormContainer;
