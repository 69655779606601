import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

const FailureSubmitModal = ({ onClose }) => (
  <Modal
    title={t('errors_something_wrong')}
    danger
    actions={[{
      id: 'ok-btn',
      label: t('close'),
      danger: true,
      inverse: true,
      onClick: onClose,
    }]}
  />
);

FailureSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FailureSubmitModal;
