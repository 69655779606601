import PropTypes from 'prop-types';
import { Button, Carousel } from 'bv-components';
import { userStakeLimitsType } from '../types';

const StakeLimitsTypesTabs = ({
  stakeLimitsTypes, stakeLimitsTypeID, onChange,
}) => (
  stakeLimitsTypes.length > 1 ? (
    <div>
      <Carousel scrollStep={234}>
        {stakeLimitsTypes.map((type) => (
          <Button
            key={type.id}
            label={type.label}
            className="bvs-button-tab"
            active={type.id === stakeLimitsTypeID}
            onClick={(e) => {
              e.preventDefault();
              onChange(type.id);
            }}
            noClass
          />
        ))}
      </Carousel>
    </div>
  ) : null
);

StakeLimitsTypesTabs.propTypes = {
  stakeLimitsTypes: userStakeLimitsType.isRequired,
  stakeLimitsTypeID: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StakeLimitsTypesTabs;
