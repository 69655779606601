import { useContext } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { userStakeLimitsType, stakeLimitsPeriodsType } from '../types';
import AggregatedStakeLimitRow from './aggregated_stake_limit_row';
import TranslationsCtx from '../translations_context';

const CurrentStakeLimitsTable = ({
  typedStakeLimits,
  stakeLimitsPeriods,
  hideRemoveBtn,
  onRemoveBtnClick,
  userStakeLimitsAreRemoving,
}) => {
  const tNamespace = useContext(TranslationsCtx);

  if (!typedStakeLimits.length) return null;

  const isNoLimitActiveLimit = typedStakeLimits.some((limit) => limit.active && !limit.period);
  const isNoLimitPendingLimit = typedStakeLimits.some((limit) => limit.pending && !limit.period);

  const getAggregatedStakeLimit = (periodName) => {
    const periodStakeLimits = typedStakeLimits.filter((limit) => limit.period === periodName);
    const activeLimit = periodStakeLimits.find((limit) => limit.active);
    const pendingLimit = periodStakeLimits.find((limit) => limit.pending);

    return {
      currentAmount: activeLimit?.amount,
      remaining: activeLimit?.remaining,
      pendingAmount: pendingLimit?.amount,
      isNoLimitPendingLimit,
    };
  };

  return (
    <div className="stake-limits__current-limits">
      <div className="flex flex--between">
        <h3>{t(`${tNamespace}.current_limits_table.title`)}</h3>
        {!hideRemoveBtn && !isNoLimitPendingLimit ? (
          <Button
            className="stake-limits__remove"
            label={t(`${tNamespace}.remove_limit`)}
            secondary
            onClick={onRemoveBtnClick}
            disabled={userStakeLimitsAreRemoving}
            loading={userStakeLimitsAreRemoving}
          />
        ) : null}
      </div>
      {isNoLimitActiveLimit ? (
        <div className="stake-limits__remove-limits__row no-limit-active">
          {t(`${tNamespace}.current_limits_table.no_limit_active`)}
        </div>
      ) : (
        <>
          <div className="stake-limits__remove-limits__row--header">
            <div>{t(`${tNamespace}.current_limits_table.th.current`)}</div>
            <div>{t(`${tNamespace}.current_limits_table.th.amount`)}</div>
            <div>{t(`${tNamespace}.current_limits_table.th.remaining`)}</div>
            <div>{t(`${tNamespace}.current_limits_table.th.pending`)}</div>
          </div>
          {stakeLimitsPeriods.map((period) => (
            <AggregatedStakeLimitRow
              key={period.id}
              label={period.label}
              {...getAggregatedStakeLimit(period.name)}
            />
          ))}
        </>
      )}
    </div>
  );
};

CurrentStakeLimitsTable.propTypes = {
  typedStakeLimits: userStakeLimitsType,
  stakeLimitsPeriods: stakeLimitsPeriodsType.isRequired,
  hideRemoveBtn: PropTypes.bool.isRequired,
  onRemoveBtnClick: PropTypes.func.isRequired,
  userStakeLimitsAreRemoving: PropTypes.bool.isRequired,
};

CurrentStakeLimitsTable.defaultProps = {
  typedStakeLimits: [],
};

export default CurrentStakeLimitsTable;
