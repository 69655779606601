import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button, Card } from 'bv-components';
import { Field, useForm } from 'react-final-form';
import { formatAmount } from '../helpers';
import Period from './fields/period';
import Amount from './fields/amount';
import { amountValidations } from '../validations';
import CreateConfirmationModal from './confirmation_modal';
import { stakeLimitsPeriodsType } from '../types';
import TranslationsCtx from '../translations_context';

const FormView = ({
  handleSubmit,
  invalid,
  pristine,
  submitting,
  values,
  showStakeLimitsPeriods,
  stakeLimitsPeriods,
  defaultPeriod,
  confirmationModalCtx,
  onConfirmModalNoClick,
}) => {
  const form = useForm();
  const { selectedPeriod, amount } = values;

  useEffect(() => {
    if (!selectedPeriod && defaultPeriod) {
      const preselectedPeriod = stakeLimitsPeriods.find((period) => period.name === defaultPeriod);
      form.change('period', preselectedPeriod);
    }
  }, [selectedPeriod, defaultPeriod]);

  useEffect(() => {
    if (selectedPeriod?.name === 'no_limit') form.change('amount', '');
  }, [selectedPeriod]);

  const tNamespace = useContext(TranslationsCtx);
  let selectedValuesDescription;

  if (pristine || invalid || !selectedPeriod) {
    selectedValuesDescription = <p>{t(`${tNamespace}.form.choose_values`)}</p>;
  } else if (selectedPeriod.name === 'no_limit') {
    selectedValuesDescription = <p>{t(`${tNamespace}.set_no_limit`)}</p>;
  } else {
    selectedValuesDescription = (
      <>
        <p>
          {t(`${tNamespace}.set_to`, {
            value: formatAmount({
              amount,
              periodLabel: selectedPeriod.label,
              hidePaddedDecimals: true,
            }),
          })}
        </p>
        <p>{t(`${tNamespace}.start_time.${selectedPeriod.name}`)}</p>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="stake-limits">
      {['ON_CREATE', 'ON_NO_LIMIT_CREATE'].includes(confirmationModalCtx) && (
        <CreateConfirmationModal
          onNoBtnClick={onConfirmModalNoClick}
          onYesBtnClick={() => form.submit(values)}
        >
          {confirmationModalCtx === 'ON_NO_LIMIT_CREATE' ? (
            <p>{t(`${tNamespace}.confirmation_modal.no_limit_notice`)}</p>
          ) : null}
        </CreateConfirmationModal>
      )}
      <Card>
        {showStakeLimitsPeriods ? (
          <div className="stake-limits__field">
            <label htmlFor="period">{t(`${tNamespace}.form.fields.period.label`)}</label>
            <Field
              name="selectedPeriod"
              component={Period}
              stakeLimitsPeriods={stakeLimitsPeriods}
            />
          </div>
        ) : null}
        <div className="stake-limits__field">
          <label htmlFor="amount">{t(`${tNamespace}.form.fields.amount.label`)}</label>
          <Field
            id="amount"
            name="amount"
            component={Amount}
            validate={amountValidations}
            periodIsNotSelected={!selectedPeriod}
            disabled={selectedPeriod?.name === 'no_limit'}
          />
        </div>
        {selectedValuesDescription}
        <Button
          primary
          className="stake-limits__submit-btn"
          type="submit"
          label={t(`${tNamespace}.form.submit`)}
          disabled={invalid || !selectedPeriod || submitting}
          loading={submitting}
        />
      </Card>
    </form>
  );
};

FormView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  showStakeLimitsPeriods: PropTypes.bool.isRequired,
  stakeLimitsPeriods: stakeLimitsPeriodsType.isRequired,
  defaultPeriod: PropTypes.string.isRequired,
  confirmationModalCtx: PropTypes.string,
  onConfirmModalNoClick: PropTypes.func.isRequired,
};

FormView.defaultProps = {
  confirmationModalCtx: '',
};

export default FormView;
