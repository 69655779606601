import PropTypes from 'prop-types';
import { Button, Carousel } from 'bv-components';
import { stakeLimitsPeriodsType } from '../../types';

const Period = (props) => {
  const {
    stakeLimitsPeriods,
    input: { onChange, value: selectedPeriod },
  } = props;

  return (
    stakeLimitsPeriods.length ? (
      <Carousel scrollStep={234}>
        {stakeLimitsPeriods.map((period) => (
          <Button
            key={period.id}
            label={period.label}
            className="bvs-button-chip is-small"
            active={period.id === selectedPeriod.id}
            onClick={(e) => {
              e.preventDefault();
              onChange(period);
            }}
          />
        ))}
      </Carousel>
    ) : null
  );
};

Period.propTypes = {
  stakeLimitsPeriods: stakeLimitsPeriodsType.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
};

export default Period;
