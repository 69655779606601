import PropTypes from 'prop-types';
import classnames from 'classnames';
import { formatAmount } from '../helpers';

const ActiveStakeLimitRow = ({
  key,
  onClick,
  checked,
  label,
  amount,
  remaining,
}) => {
  const classes = classnames('stake-limits__remove-limits__row', { active: checked });

  const checkboxLabelClasses = classnames('account-form__checkbox', { active: checked });

  return (
    <div onClick={onClick} className={classes}>
      <div className="bvs-checkbox-card__checkbox">
        <label htmlFor={`sl_checkbox_${key}`} className={checkboxLabelClasses}>
          <input type="checkbox" className="account-form__hidden" id={`sl_checkbox_${key}`} checked={checked} />
          <span>{label}</span>
        </label>
      </div>

      <div>
        {formatAmount({ amount })}
      </div>
      <div>
        {remaining ? formatAmount({ amount: remaining }) : '-'}
      </div>
    </div>
  );
};

ActiveStakeLimitRow.propTypes = {
  key: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  remaining: PropTypes.number.isRequired,
};

export default ActiveStakeLimitRow;
